<template>
	<div class="view pa24">
		<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
			<template v-slot:table>
				<el-table-column prop="windowsName" align="center" label="名称" />
				<el-table-column prop="windowsPerson" align="center" label="编码" />
				<el-table-column prop="createName" align="center" label="费率" />
				<el-table-column prop="createName" align="center" label="状态" />
			</template>
		</commonTable>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	export default {
		name: '',
		data() {
			return {
				fashiList: [],
				tableData: [],
				currentPage: 1, //当前页
				pageSize: 10, //显示条数
				loading: false, //表格加载
				total: 0, //总条数
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			// this.queryPage();
		},

		methods: {
			async queryPage() {
				let data = {
					pageSize: this.pageSize,
					pageNum: this.currentPage,
				};
				try {
					this.loading = true;
					const result = await selectWindowsConfigPageList(data);
					this.loading = false;
					const {
						total,
						list
					} = result.data.pageInfo;
					for (let i in list) {
						list[i].createTime = this.renderTime(list[i].createTime)
						if (list[i].status == 0) {
							list[i].status = true
						} else {
							list[i].status = false
						}
					}
					this.tableData = list;
					this.total = total;
				} catch (error) {
					this.tableData = []
					this.loading = false;
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			/**@method 编辑
			 * @param {Object} val - 当前点击行的值
			 */
			edit(val, b) {
				let data = JSON.parse(JSON.stringify(val));
				this.centerDialogVisible = true;
				this.ruleForm.account = data.windowsName;
				this.ruleForm.bank = data.windowsPerson;
				this.ruleForm.zhihang = data.status;
				this.ruleForm.name = data.officeId
				this.ruleForm.ka = data.officeId
				this.ruleForm.desc = data.officeId
				this.bdetermine = b;
				this.bEdit = b;
				this.windowsConfigId = data.windowsConfigId
			},
			/**@method 添加 */
			determine() {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								account: this.ruleForm.account,
								bank: this.ruleForm.bank,
								zhihang: this.ruleForm.zhihang,
								name: this.ruleForm.name,
								ka: this.ruleForm.ka,
								desc: this.ruleForm.desc,
							};
							this.$message({
								message: "正在保存",
							});
							if (this.editUpdate) {
								data.windowsConfigId = this.windowsConfigId
								await upWindowsConfig(data);
							} else {
								await addWindowsConfig(data);
							}
							this.centerDialogVisible = false;
							this.$message.closeAll();
							this.$message({
								message: "保存成功",
								type: "success",
							});
							await this.queryPage();
						} catch (error) {
							this.$message.closeAll();
							this.$message({
								message: error.message,
								type: "error",
							});
							this.centerDialogVisible = false;
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			/**@method 删除
			 * @param {String}
			 */
			async del(val) {
				try {
					await delWindowsConfig({
						windowsConfigId: val
					});
					await this.queryPage();
				} catch (error) {
					console.log(error);
				}
			},
			search() {

			}
		},
	};
</script>

<style lang="scss" scoped>
</style>